body {
  font-size: 16px;
  overflow: hidden;
  background-color: #f8f8f8;
  font-family: "proxima-nova";
}

#root {
  height: 100vh;
  width: 100vw;
}
